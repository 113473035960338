addEventListener("direct-upload:initialize", event => {
  const target = document.getElementById("progress-bar")
  const fileName = document.getElementById("file-name")
  const { detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  fileName.textContent = file.fileName
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const uploadButton = document.getElementById(`upload-button`)
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
  uploadButton.disabled = true

  const progressValue = document.getElementById("progress-value")
  const progressStatus = document.getElementById("progress-status")
  progressStatus.textContent = "Starting"
  progressValue.textContent = "0%"
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
  const progressValue = document.getElementById("progress-value")
  const progressStatus = document.getElementById("progress-status")
  progressStatus.textContent = "Uploading..."
  progressValue.textContent = `${progress.toFixed(2)}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const uploadButton = document.getElementById(`upload-button`)
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
  uploadButton.classList.remove('hidden')

  const progressValue = document.getElementById("progress-value")
  const progressStatus = document.getElementById("progress-status")
  progressStatus.textContent = ""
  progressValue.textContent = ``
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
  const progressValue = document.getElementById("progress-value")
  const progressStatus = document.getElementById("progress-status")
  progressStatus.textContent = "Done!"
  progressValue.textContent = `100%`
})