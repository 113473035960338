document.addEventListener("turbo:load", function() {
    let videoImages = document.querySelectorAll(".video-image");
    for (let i = 0; i < videoImages.length; i++) {
      let thumbnailUrl = videoImages[i].getAttribute("src");
      let previewUrl = videoImages[i].getAttribute("data-preview-url");
      videoImages[i].addEventListener("mouseover", function() {
        this.setAttribute("src", previewUrl);
      });
      videoImages[i].addEventListener("mouseout", function() {
        this.setAttribute("src", thumbnailUrl);
      });
    }
  });