console.log('Vite ⚡️ Rails')

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import * as Turbo from '@hotwired/turbo'
Turbo.start()

import "chartkick/chart.js"

import '../directUpload.js'
import '../modalTriggers.js'
import '../navbar.js'
import '../videoImage.js'
